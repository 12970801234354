@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

body {
  margin: 0;
}
.rotate-180 {
  transform: rotate(180deg);
}
.slick-slide {
  margin: 0 10px;
}
.slick-list {
  margin: 0 -10px;
  height: 55ch;
}

::-webkit-scrollbar-track {
  background-color: white;
}
.container {
  margin: 0 auto;
  width: 100%;
  height: 80vh;
  border-color: white;
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #eeeeee;
  border-radius: 1rem;
  border: 3px solid transparent;
  background-clip: content-box;
  width: 2px;
}

::-moz-scrollbartrack-vertical {
  background-color: rgb(255, 255, 255);
}

::-moz-scrollbar {
  width: 10px;
  height: 10px;
}

::-moz-scrollbarbutton-up {
  background-color: gray;
}

@media only screen and (max-width: 600px) {
  .slick-slide {
    margin: 0;
  }
  .slick-list {
    margin: 0;
    height: fit-content;
  }
}
